<section class="section about" id="about">
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class="n-section-title">01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor="let text of 'AboutMe.Paragraphs' | translate" [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Python </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Angular </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">HTML &amp; (S)CSS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">JavaScript </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Express </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Node Js </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">TypeScript</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">RxJS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Tensorflow </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">IDL (Envi) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">PostgreSQL </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">MongoDB </span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img class="rounded" width="300" height="300" src="assets/images/avatar.jpg" alt="Paola Montoya">
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
