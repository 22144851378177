<section class="section jobs" id="jobs">
    <div class="container">
        <div class="section-box">
            <div class="about-title mb-5" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="e-font section-title">
                    <span class="code-font n-section-title">02.</span> {{"Experience.Title" | translate}}
                </h3>
            </div>
            <div class="jobs-tabs" data-aos="fade-up" data-aos-duration="1000">
              <ul ngbNav #nav="ngbNav" [(activeId)]="active" orientation="vertical" class="nav-tabs jobs-tabs">
                    <li *ngFor="let job of 'Experience.Jobs' | translate; let i = index" [title]="job.Tab" [ngbNavItem]="i">
                      <a ngbNavLink>{{job.Tab}}</a>
                        <ng-template ngbNavContent>
                          <h4 class="title-tab-content">{{job.Title}} | <span class="company-tab-content">{{job.Tab}}</span></h4>
                          <h5 class="job-time">{{job.Date}}</h5>
                          <!-- {{job.Description | json}} -->
                          <p *ngFor="let jobDescriptionParagraph of job.Description; let i = index" class="job-description"
                          [innerHTML]="jobDescriptionParagraph | translate"></p>
                          <span></span>
                        </ng-template>
                    </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>

        </div>
    </div>
</section>
